import { format } from 'date-fns';
import { useState } from 'react';
import { Typography } from '@mui/material';

import { JobSummary } from 'domain/entities/JobSummary';
import { useCaseSearchJobs } from 'application/jobs/useCases/useCaseSearchJobs';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Table, TableColumn } from 'targets/web/components';
import ReactRouterLink from 'targets/web/components/ReactRouterLink';
import Chip from 'targets/web/components/Chip';
import { useJobViewFilters } from 'targets/web/modules/jobs/hooks/useJobViewFilters';
import {
  JobBillingStatusChip,
  JobStatusChip,
} from 'targets/web/modules/jobs/components/StatusChips';
import { utcToLocalTime } from 'targets/web/modules/jobs/utils';

export const JobsTable = () => {
  const t = useTranslationPrefix('jobs.jobs_view.table');
  const [currentPage, setCurrentPage] = useState(0);

  const { filters, jobFilters, setFilters, setSort } = useJobViewFilters();
  const { total, pages, fetchNextPage, isFetching, isLoading } = useCaseSearchJobs(jobFilters);

  const columns: TableColumn<JobSummary>[] = [
    {
      headerName: t('columns.job_name'),
      field: 'name',
      sortable: true,
      renderCell: ({ name, id }) => (
        <ReactRouterLink
          data-testname={`job-id-${id}`}
          to={`/jobs/${id}/summary`}
          variant="body1"
          color="primary"
        >
          {name}
        </ReactRouterLink>
      ),
    },
    {
      headerName: t('columns.services'),
      width: '5%',
      renderCell: ({ completedServices, totalServices }) => (
        <Chip
          label={t('services_chip_label', { completedServices, totalServices })}
          color="primary"
          size="small"
        />
      ),
    },
    {
      headerName: t('columns.status'),
      field: 'status',
      renderCell: ({ id, status, billingStatus, due }) => (
        <JobStatusChip
          id={id}
          status={status}
          due={due}
          billingStatus={billingStatus}
          optimisticPermissions
        />
      ),
    },
    {
      headerName: t('columns.billing_status'),
      width: '5%',
      renderCell: ({ id, billingStatus, status, services, priceModifiers }) => (
        <JobBillingStatusChip
          id={id}
          billingStatus={billingStatus}
          status={status}
          services={services}
          priceModifiers={priceModifiers}
          optimisticPermissions
        />
      ),
    },
    {
      headerName: t('columns.station'),
      field: 'station',
      sortable: true,
      renderCell: ({ station }) => <Typography>{station?.code}</Typography>,
    },
    {
      headerName: t('columns.customer'),
      field: 'customer',
      sortable: true,
      renderCell: ({ customer }) => <Typography noWrap>{customer.code}</Typography>,
    },
    {
      headerName: t('columns.due_date'),
      field: 'due',
      sortable: true,
      width: '5%',
      renderCell: ({ due }) => (
        <Typography noWrap>{format(utcToLocalTime(due), 'MMM dd, yyyy')}</Typography>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      rows={pages[currentPage]?.items ?? []}
      rowsPerPageOptions={[10, 50, 100]}
      initialRowsPerPage={filters?.limit ?? 10}
      count={total}
      onRowsPerPageChange={(rowsPerPage) => {
        setFilters((current) => ({ ...current, limit: rowsPerPage }));
        setCurrentPage(0);
      }}
      onPageChange={async (pageNumber) => {
        if (!pages[pageNumber]) {
          await fetchNextPage();
        }

        setCurrentPage(pageNumber);
      }}
      onSortChange={(sortBy, direction) => {
        setSort({ sortBy, direction });
        setCurrentPage(0);
      }}
      initialPage={currentPage}
      isLoading={isLoading || isFetching}
    />
  );
};
