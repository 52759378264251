import { FC } from 'react';
import { Stack, Typography } from '@mui/material';
import { useFormikContext } from 'formik';
import AddIcon from '@mui/icons-material/Add';
import z from 'zod';

import { entityId, ID } from 'domain/types/ID';
import { useCaseGetMatchedServicePrices } from 'application/prices/useCases/useCaseGetMatchedServicePrices';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Button, Loader } from 'targets/web/components';

import ServiceFormCard, { ServiceFormCardSchema, serviceFormCardSchema } from './ServiceFormCard';
import { Container } from './common';

export const ServicesJobDetailsSchema = z.object({
  services: z.array(serviceFormCardSchema).min(1),
  customer: z.object({
    id: entityId(),
  }),
  station: z.object({
    id: entityId(),
  }),
  aircraft: z.object({
    id: entityId(),
    code: z.string().optional(),
    type: z.object({
      name: z.string(),
      code: z.string(),
    }),
    typeId: entityId(),
    serialNumber: z.string().optional().nullable(),
  }),
});

export type ServicesJobDetailsData = z.infer<typeof ServicesJobDetailsSchema>;

export interface ServicesJobDetailsProps {
  disabled?: boolean;
  enableEstimates?: boolean;
  enableBilling?: boolean;
}

export const ServicesJobDetails: FC<ServicesJobDetailsProps> = ({
  disabled,
  enableEstimates,
  enableBilling,
}) => {
  const { values, setFieldValue } = useFormikContext<ServicesJobDetailsData>();
  const t = useTranslationPrefix('jobs.details_form.services_details');

  const { isLoading, priceLists } = useCaseGetMatchedServicePrices({
    aircraftTypeId: values.aircraft?.typeId,
    customerId: values.customer?.id,
    stationId: values.station?.id,
  });

  const hideServiceTypeIDs = new Set(values.services.map((s) => s.serviceType?.id));

  return (
    <Container gap={4} sx={{ padding: 4 }}>
      <Typography variant="h6">{t('title')}</Typography>

      {isLoading && (
        <Stack height={100} justifyContent="center" alignItems="center">
          <Loader />
        </Stack>
      )}

      {!isLoading &&
        values.services.map((service, index) => (
          <ServiceFormCard
            index={index}
            key={`service_${index}`}
            isLoading={isLoading}
            priceLists={priceLists || []}
            enableEstimates={enableEstimates}
            enableBilling={enableBilling}
            service={service}
            hideServiceTypesIDs={hideServiceTypeIDs}
            onRemove={() => {
              setFieldValue(
                'services',
                values.services.filter((_, i) => i !== index),
              );
            }}
            onChange={(changedValues) => {
              values.services[index] = { ...values.services[index], ...changedValues };
              setFieldValue('services', [...values.services]);
            }}
            disabled={disabled}
          />
        ))}

      {!disabled && (
        <Stack alignItems="flex-start">
          <Button
            onClick={() =>
              setFieldValue('services', [
                ...values.services,
                {
                  estimatedTime: 1,
                  quantity: 1,
                  name: '',
                  priceList: {
                    id: ID(''),
                    name: '',
                  },
                  serviceType: {
                    id: ID(''),
                    name: '',
                  },
                  discountPercentage: null,
                } satisfies ServiceFormCardSchema,
              ])
            }
            variant="text"
            startIcon={<AddIcon />}
          >
            {t('add_service_button')}
          </Button>
        </Stack>
      )}
    </Container>
  );
};
