import { Grid, Stack, Theme, useMediaQuery } from '@mui/material';
import { FC, ReactElement, useMemo } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { toFormikValidationSchema } from 'zod-formik-adapter';
import z from 'zod';
import {
  RecurringJobValidationErrors,
  runRecurringJobValidation,
} from 'api/application/jobs/libs/runRecurringJobValidation';
import { BillingStatus } from 'api/domain/enums/BillingStatus';

import { ID } from 'domain/types/ID';
import { JobRecurring } from 'domain/entities/JobRecurring';
import { Job } from 'domain/entities/GetJobResponse';
import { CreateJobWithBilling } from 'domain/types/Roles';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { getTodaysDate } from 'targets/web/modules/jobs/utils';

import { JobInformation } from './JobInformation';
import { PrimaryJobDetails } from './PrimaryJobDetails';
import { AircraftJobDetails } from './AircraftJobDetails';
import { AvailabilityJobDetails } from './AvailabilityJobDetails';
import { NotesJobDetails } from './NotesJobDetails';
import { ServicesJobDetails } from './ServicesJobDetails';
import { useAddJobSelectDisabledLogic } from './useAddJobSelectDisabledLogic';
import { JobDetailsData, JobDetailsDataSchema } from './JobDetailsDataSchema';

export interface JobDetailsProps {
  initialValues?: JobDetailsData;
  onSubmit?: (values: JobDetailsData) => void | Promise<void>;
  disabled?: boolean;
  footer?: ReactElement;
  job?: Job | null;
  jobId?: string;
  stationEditable?: boolean;
  customerEditable?: boolean;
  aircraftEditable?: boolean;
}

const emptyInitialValues = {
  station: { id: ID(''), code: '', name: '', state: '' },
  airport: { id: ID(''), code: '', name: '', state: '' },
  facility: { id: ID(''), code: '', name: '', state: '' },
  customer: { id: ID(''), code: '', name: '' },
  aircraft: {
    id: ID(''),
    code: '',
    type: { name: '', code: '' },
    typeId: ID(''),
  },
  payment: {
    poNumber: '',
    woNumber: '',
    squawkNumber: '',
  },
  deliverBy: getTodaysDate(),
  services: [],
  recurringType: JobRecurring.Values.none,
  recurringStartsAt: undefined,
  recurringEndsAt: undefined,
  flatRate: undefined,
};

export const JobDetailsForm: FC<JobDetailsProps> = ({
  initialValues,
  onSubmit,
  footer,
  job,
  jobId,
  disabled,
  stationEditable,
  customerEditable,
  aircraftEditable,
}) => {
  const t = useTranslationPrefix('jobs.details_form');
  const isMobile = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'));
  const isSentToSage = useMemo(
    () => job?.billingStatus === BillingStatus.Values.sent_to_sage,
    [job?.billingStatus],
  );

  const { granted: updateServiceEstimateAccess } = usePermission(
    jobId
      ? {
          resource: `${JobResourceType}:${ID(jobId)}`,
          scope: 'update-estimate',
        }
      : undefined,
  );

  const { granted: canUpdateBilling } = usePermission(
    jobId
      ? {
          resource: `${JobResourceType}:${ID(jobId)}`,
          scope: 'update-billing-amount',
        }
      : undefined,
  );
  const { hasRole } = useCaseCheckAccess();
  const canEditBilling = jobId ? canUpdateBilling : hasRole(CreateJobWithBilling);

  const isRecurringParent = !!job && !job.parentId && job.recurringType !== 'none';

  const validationSchema = useMemo(() => {
    return JobDetailsDataSchema.superRefine((values, ctx) => {
      const error = runRecurringJobValidation({
        recurringType: values.recurringType,
        recurringStartsAt: values.recurringStartsAt,
        recurringEndsAt: values.recurringEndsAt,
      });
      if (error === RecurringJobValidationErrors.RECURRING_START_DATE_NOT_PROVIDED) {
        ctx.addIssue({
          fatal: true,
          code: z.ZodIssueCode.custom,
          path: ['recurringStartsAt'],
          message: t('availability_details.validation_error.contract_start_requiredness'),
        });
      }
      if (error === RecurringJobValidationErrors.RECURRING_END_DATE_BEFORE_START_DATE) {
        ctx.addIssue({
          fatal: true,
          code: z.ZodIssueCode.custom,
          path: ['recurringEndsAt'],
          message: t('availability_details.validation_error.end_before_start'),
        });
      }
      if (error === RecurringJobValidationErrors.RECURRING_START_DATE_INVALID) {
        ctx.addIssue({
          fatal: true,
          code: z.ZodIssueCode.custom,
          path: ['recurringStartsAt'],
          message: t('availability_details.validation_error.contract_start_invalid_date'),
        });
      }
      if (error === RecurringJobValidationErrors.RECURRING_END_DATE_INVALID) {
        ctx.addIssue({
          fatal: true,
          code: z.ZodIssueCode.custom,
          path: ['recurringEndsAt'],
          message: t('availability_details.validation_error.contract_end_invalid_date'),
        });
      }
    });
  }, [t]);

  const formik = useFormik({
    initialValues: initialValues ?? emptyInitialValues,
    enableReinitialize: true,
    onSubmit: async (data) => {
      if (!onSubmit) {
        return;
      }

      await onSubmit({
        ...data,
        services: data.services.filter(
          ({ serviceType, priceList }) => Boolean(serviceType) && Boolean(priceList),
        ),
      });
    },
    validateOnMount: true,
    validateOnChange: true,
    validationSchema: toFormikValidationSchema(validationSchema),
  });

  const { customerSelectEnabled, aircraftSelectEnabled, serviceSelectEnabled } =
    useAddJobSelectDisabledLogic({
      ...formik,
      values: {
        ...formik.values,
        airport: formik.values.station as { id: ID; name: string; code: string; state: string },
        facility: formik.values.station as { id: ID; name: string; code: string; state: string },
      },
    });

  const ServicesJobElement = (
    <ServicesJobDetails
      disabled={disabled || !serviceSelectEnabled || isRecurringParent || isSentToSage}
      enableEstimates={jobId ? updateServiceEstimateAccess : true}
      enableBilling={canEditBilling}
    />
  );

  return (
    <FormikProvider value={formik}>
      <Form>
        <Stack gap={4}>
          <Grid container spacing={4}>
            <Grid item xs md={6}>
              <Stack gap={4}>
                {job && <JobInformation job={job} />}
                <PrimaryJobDetails
                  disabled={disabled || isSentToSage}
                  stationEditable={stationEditable}
                  customerEditable={customerEditable && customerSelectEnabled}
                />
                <AircraftJobDetails
                  disabled={disabled || !aircraftSelectEnabled || isRecurringParent || isSentToSage}
                  aircraftEditable={aircraftEditable}
                  paymentDisabled={disabled || isRecurringParent}
                />

                {isMobile && ServicesJobElement}

                <AvailabilityJobDetails disabled={disabled} parent={job?.parent} enableDateEdit />
                <NotesJobDetails disabled={disabled || isRecurringParent || isSentToSage} />
              </Stack>
            </Grid>

            {!isMobile && (
              <Grid item xs={6}>
                {ServicesJobElement}
              </Grid>
            )}
          </Grid>

          {footer}
        </Stack>
      </Form>
    </FormikProvider>
  );
};
