import { FODInspectionPart } from 'api/domain/entities/documents/FODInspection';
import contract from 'api/infrastructure/contract/jobs.route';
import { useCallback } from 'react';

import { JobRepository, SearchJobsParams } from 'domain/repositories/IJobRepository';
import { ID } from 'domain/types/ID';
import useTsRestClient from 'infrastructure/providers/useTsRestClient';

const transformParams = ({ sortBy, ...params }: SearchJobsParams) => {
  return {
    ...params,
    sortBy: sortBy === 'customer' || sortBy === 'station' ? `${sortBy}.name` : sortBy,
  };
};

type RepositoryFunctions = ReturnType<JobRepository>;

export const useTsRestJobRepository: JobRepository = () => {
  const client = useTsRestClient(contract);

  const searchJobs: RepositoryFunctions['searchJobs'] = useCallback(
    async (params) => {
      const res = await client.searchJobs({
        query: transformParams(params),
      });

      if (res.status === 200) {
        return {
          ...res.body,
          items: res.body.items.map((job) => ({
            ...job,
            due: new Date(job.due),
            scheduledStart: new Date(job.scheduledStart),
            eta: new Date(job.eta),
            etd: new Date(job.etd),
          })),
        };
      }

      throw res;
    },
    [client],
  );

  const fetchJob: RepositoryFunctions['fetchJob'] = useCallback(
    async (params) => {
      if (!params.id) throw new Error('Missing job id');
      const res = await client.getJob({
        params: { jobId: ID(params.id) },
      });

      if (res.status === 200) {
        return {
          ...res.body,
          createdAt: new Date(res.body.createdAt),
          startedAt: res.body.startedAt && new Date(res.body.startedAt),
          due: new Date(res.body.due),
          scheduledStart: new Date(res.body.scheduledStart),
          eta: new Date(res.body.eta),
          etd: new Date(res.body.etd),
          poNumber: res.body.poNumber ?? undefined,
          woNumber: res.body.woNumber ?? undefined,
          squawkNumber: res.body.squawkNumber ?? undefined,
          services: res.body.services.map((service) => ({
            ...service,
            // FIXME: We should reuse the same mapping method as for services
            scheduledDue: new Date(service.scheduledDue),
            scheduledStart: service.scheduledStart && new Date(service.scheduledStart),
            completedAt: service.completedAt && new Date(service.completedAt),
            workLogs: service.workLogs.map((worklog) => ({
              ...worklog,
              startedAt: new Date(worklog.startedAt),
              endedAt: worklog.endedAt && new Date(worklog.endedAt),
            })),
            inspectionAreas: service.inspectionAreas as FODInspectionPart[],
          })),
          recurringStartsAt: res.body.recurringStartsAt
            ? new Date(res.body.recurringStartsAt)
            : undefined,
          recurringEndsAt: res.body.recurringEndsAt
            ? new Date(res.body.recurringEndsAt)
            : undefined,
        };
      }
      throw res;
    },
    [client],
  );

  const updateJob: RepositoryFunctions['updateJob'] = useCallback(
    async (params) => {
      const response = await client.updateJob({
        params: { jobId: params.jobId },
        body: {
          ...params.data,
          ...(params.data.services
            ? {
                serviceTypes: params.data.services,
              }
            : {}),
          recurringStartsAt: params.data.recurringStartsAt,
          recurringEndsAt: params.data.recurringEndsAt,
        },
      });

      if (response.status !== 204) {
        throw response;
      }
    },
    [client],
  );

  const setBillingStatus: RepositoryFunctions['setBillingStatus'] = useCallback(
    async ({ jobId, billingStatus, enabledPriceModifierIds }) => {
      const response = await client.setBillingStatus({
        params: { jobId },
        body: { billingStatus, enabledPriceModifierIds },
      });

      if (response.status !== 204) {
        throw response;
      }
    },
    [client],
  );

  const getCountByStatus: RepositoryFunctions['getCountByStatus'] = useCallback(
    async (params) => {
      const res = await client.getCountJobsStatus({
        query: transformParams(params),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const getJobsSummary: RepositoryFunctions['getJobsSummary'] = useCallback(
    async (params) => {
      const res = await client.getCountJobsSummary({
        query: transformParams(params),
      });

      if (res.status === 200) {
        return res.body;
      }

      throw res;
    },
    [client],
  );

  const createJob: RepositoryFunctions['createJob'] = useCallback(
    async (params) => {
      const res = await client.createJob({
        body: {
          ...params,
          services: params.services,
          recurringStartsAt: params.recurringStartsAt,
          recurringEndsAt: params.recurringEndsAt,
        },
      });
      if (res.status === 201) {
        return res.body;
      }
      throw res;
    },
    [client],
  );

  const cancelJob: RepositoryFunctions['cancelJob'] = useCallback(
    async (params) => {
      const res = await client.cancel({
        params: { jobId: params.jobId },
        body: params,
      });

      if (res.status !== 204) {
        throw res;
      }
    },
    [client],
  );

  const completeJob: RepositoryFunctions['completeJob'] = useCallback(
    async (params) => {
      const res = await client.complete({
        params: { jobId: params.jobId },
      });

      if (res.status !== 204) {
        throw res;
      }
    },
    [client],
  );

  return {
    searchJobs,
    getCountByStatus,
    fetchJob,
    getJobsSummary,
    createJob,
    updateJob,
    setBillingStatus,
    cancelJob,
    completeJob,
  };
};
