import { Stack, styled, Typography } from '@mui/material';
import {
  AttachMoneyOutlined as DollarIcon,
  CloseOutlined,
  MoneyOffCsredOutlined as MoneyOffIcon,
  VisibilityOutlined,
} from '@mui/icons-material';
import { addMilliseconds, intervalToDuration } from 'date-fns';

import { JobSummary } from 'domain/entities/JobSummary';
import { ID } from 'domain/types/ID';
import usePermission from 'application/auth/hooks/usePermission';
import { JobResourceType } from 'application/auth/utils/resources';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { componentShadows } from 'targets/web/theme/shadows';
import { Button, Chip, PreviewBox, ProgressBar } from 'targets/web/components';
import {
  JobBillingStatusChip,
  JobStatusChip,
} from 'targets/web/modules/jobs/components/StatusChips';
import { formatDuration } from 'targets/web/modules/jobs/utils';

const Container = styled(Stack)(({ theme }) => ({
  padding: theme.spacing(6),
  minWidth: 260,
  width: 'inherit',
  maxWidth: 375,
  minHeight: 460,
  borderRadius: theme.shape.borderRadius,
  backgroundColor: theme.palette.background.default,
  boxShadow: componentShadows.card,
}));

type JobLogCardProps = Pick<
  JobSummary,
  | 'id'
  | 'name'
  | 'completedServices'
  | 'totalServices'
  | 'status'
  | 'customer'
  | 'aircraft'
  | 'notes'
  | 'station'
  | 'workLogTimeSummary'
  | 'due'
  | 'billingStatus'
  | 'services'
  | 'priceModifiers'
> & {
  isPreviewDetailsVisible?: boolean;
  onClick?: () => void;
  onViewDetailsClick?: () => void;
  onCancelClick?: () => void;
  optimisticPermissions?: boolean;
};

export const JobLogCard = ({
  id,
  name,
  completedServices = 0,
  totalServices = 0,
  status,
  customer,
  aircraft,
  notes,
  station,
  workLogTimeSummary,
  due,
  isPreviewDetailsVisible,
  billingStatus,
  services,
  priceModifiers,
  onClick,
  onViewDetailsClick,
  onCancelClick,
  optimisticPermissions,
}: JobLogCardProps) => {
  const t = useTranslationPrefix('components.cards.job_card');
  const hasTimeTracked = workLogTimeSummary.billable || workLogTimeSummary.nonBillable;

  const { granted: hasCancelAccess } = usePermission(
    {
      resource: `${JobResourceType}:${ID(id)}`,
      scope: 'cancel',
    },
    optimisticPermissions,
  );

  const isCancelButtonVisible = onCancelClick && hasCancelAccess;
  const isActionsSectionVisible = isPreviewDetailsVisible || isCancelButtonVisible;

  return (
    <Container gap={4} onClick={onClick} sx={{ cursor: 'pointer' }} data-testname="jobCard">
      <Stack gap={2} alignItems="center" direction="row">
        <ProgressBar sx={{ flex: 1 }} value={(completedServices / totalServices) * 100} />

        <Chip
          label={t('services_chip_label', { completedServices, totalServices })}
          size="small"
          color="primary"
        />
      </Stack>

      <Stack gap={2}>
        <Typography data-testname={`job_${id}`} variant="h5">
          {name}
        </Typography>

        <Stack direction="row" gap={2}>
          <JobStatusChip
            due={due}
            id={id}
            billingStatus={billingStatus}
            status={status}
            optimisticPermissions={optimisticPermissions}
          />

          <JobBillingStatusChip
            id={id}
            billingStatus={billingStatus}
            status={status}
            optimisticPermissions={optimisticPermissions}
            services={services}
            priceModifiers={priceModifiers}
          />
        </Stack>
      </Stack>

      <Stack gap={2} flex={1}>
        <PreviewBox
          flex={1}
          size="regular"
          direction="row"
          width="auto"
          justifyContent="start"
          alignItems="flex-start"
        >
          <Stack flex={1} gap={2}>
            {[
              [t('station'), station?.code],
              [t('customer'), customer?.code],
              [t('aircraft_type'), aircraft?.type.code],
            ].map(([label, value], index) => (
              <Stack alignSelf="start" gap={1} key={index}>
                <Typography variant="inputLabel">{label}</Typography>
                <Typography data-testname={`${label}-value`} variant="labelSmall">
                  {value}
                </Typography>
              </Stack>
            ))}
          </Stack>
          <Stack flex={1} gap={2}>
            {[
              [t('registration_number'), aircraft?.code || '-'],
              [t('serial_number'), aircraft?.serialNumber || '-'],
            ].map(([label, value], index) => (
              <Stack alignSelf="start" gap={1} key={index}>
                <Typography variant="inputLabel">{label}</Typography>
                <Typography data-testname={`${label}-value`} variant="labelSmall">
                  {value}
                </Typography>
              </Stack>
            ))}
          </Stack>
        </PreviewBox>

        {notes && (
          <PreviewBox sx={{ padding: 4 }}>
            <Stack direction="row" gap={1} flexWrap="wrap">
              <Typography variant="tooltip" color="secondary">
                {t('notes')}
              </Typography>
              <Typography
                variant="tooltip"
                color="secondary"
                sx={{
                  wordBreak: 'break-word',
                  display: '-webkit-box',
                  WebkitLineClamp: 3,
                  WebkitBoxOrient: 'vertical',
                  overflow: 'hidden',
                }}
              >
                {notes}
              </Typography>
            </Stack>
          </PreviewBox>
        )}

        {!hasTimeTracked ? (
          <PreviewBox
            justifyContent="center"
            color="warning"
            icon={<DollarIcon />}
            subheader={t('no_time_tracked')}
          />
        ) : (
          <Stack width={1} direction="row" gap={2} flexWrap="wrap">
            <PreviewBox
              flex={1}
              color="success"
              icon={<DollarIcon />}
              header={t('billable')}
              subheader={formatDuration(
                intervalToDuration({
                  start: 0,
                  end: addMilliseconds(0, workLogTimeSummary.billable * 1000),
                }),
              )}
            />

            <PreviewBox
              flex={1}
              color="secondary"
              icon={<MoneyOffIcon />}
              header={t('non_billable')}
              subheader={formatDuration(
                intervalToDuration({
                  start: 0,
                  end: addMilliseconds(0, workLogTimeSummary.nonBillable * 1000),
                }),
              )}
            />
          </Stack>
        )}
      </Stack>

      {isActionsSectionVisible && (
        <Stack gap={2}>
          {isPreviewDetailsVisible && (
            <Button
              variant="outlined"
              size="large"
              startIcon={<VisibilityOutlined />}
              onClick={onViewDetailsClick}
            >
              {t('view_details')}
            </Button>
          )}

          {isCancelButtonVisible && (
            <Button
              color="error"
              variant="text"
              size="large"
              startIcon={<CloseOutlined />}
              onClick={onCancelClick}
              disabled={status === 'canceled'}
            >
              {t('cancel_job')}
            </Button>
          )}
        </Stack>
      )}
    </Container>
  );
};
