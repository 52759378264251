import CheckIcon from '@mui/icons-material/CheckOutlined';
import { Chip, Stack, Typography } from '@mui/material';
import { format } from 'date-fns';
import { useEffect, useMemo, useState } from 'react';

import { BillingItem } from 'domain/entities/BillingItem';
import { getPriceSummary } from 'domain/entities/JobPriceModifer';
import { ConfirmBilling } from 'domain/types/Roles';
import { useCaseCheckAccess } from 'application/auth/useCases/useCaseCheckAccess';
import { useCaseConfirmBillingItem } from 'application/billing/useCases/useCaseConfirmBillingItem';
import {
  BillingItemExtraKeys,
  useCaseSearchBillingItems,
} from 'application/billing/useCases/useCaseSearchBillingItems';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import formatPrice from 'infrastructure/utils/formatPrice';
import { Button, ReactRouterLink, Table, TableColumn, Tooltip } from 'targets/web/components';
import { ConfirmBillingItemModal } from 'targets/web/modules/billing/components/ConfirmBillingItemModal';
import { ConfirmCustomerFlow } from 'targets/web/modules/billing/components/ConfirmCustomerFlow';
import { OfflinePlaceholder } from 'targets/web/modules/dashboard/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';

export const BillingItemsTable = () => {
  const t = useTranslationPrefix('billing.billing_review');
  const tGlobal = useTranslationPrefix('global');
  const notify = useSnackbar();
  const { isOffline } = useNetworkStatus();
  const { hasRole } = useCaseCheckAccess();

  const {
    items = [],
    total,
    sorting,
    paging,
    isFetching,
    isLoading,
    changePage,
    changePageSize,
    changeSort,
  } = useCaseSearchBillingItems();
  const { confirmBillingItem } = useCaseConfirmBillingItem();

  const [selectedBillingItem, setSelectedBillingItem] = useState<BillingItem | null>(null);
  const [state, setState] = useState<'confirmCustomer' | 'confirmBilling'>('confirmCustomer');

  useEffect(() => {
    setState(selectedBillingItem?.customer.externalId ? 'confirmBilling' : 'confirmCustomer');
  }, [selectedBillingItem]);

  const columns: (TableColumn<BillingItem, BillingItemExtraKeys> | undefined)[] = useMemo(
    () => [
      {
        headerName: t('table.job_name'),
        field: 'job.name',
        sortable: true,
        renderCell: ({ job, id }) => (
          <ReactRouterLink
            data-testname={`job-${id}`}
            to={`/jobs/${id}/summary`}
            variant="body1"
            color="primary"
            target="_blank"
          >
            {job.name}
          </ReactRouterLink>
        ),
      },
      {
        headerName: t('table.po_number'),
        field: 'poNumber',
        sortable: true,
        renderCell: ({ job }) => (
          <Stack direction="row" gap={2} alignItems="center">
            <Stack>
              <Typography>{job.poNumber || '-'}</Typography>
            </Stack>
          </Stack>
        ),
      },
      {
        headerName: t('table.customer'),
        field: 'customer.name',
        sortable: true,
        renderCell: ({ customer }) => (
          <Stack direction="row" gap={2} alignItems="center">
            <Stack>
              <Typography>{customer.name}</Typography>

              <Typography variant="body2" color="text.secondary">
                {customer.code}
              </Typography>
            </Stack>

            {!customer.externalId && (
              <Tooltip title={t('new_customer_tooltip')}>
                <Chip label={t('new_customer_chip')} size="small" color="success" />
              </Tooltip>
            )}
          </Stack>
        ),
      },
      {
        headerName: t('table.station'),
        field: 'station.name',
        sortable: true,
        renderCell: ({ station }) => (
          <Stack direction="row" gap={2} alignItems="center">
            <Stack>
              <Typography>{station.name}</Typography>

              <Typography variant="body2" color="text.secondary">
                {station.code}
              </Typography>
            </Stack>

            {!station.externalId && (
              <Tooltip title={t('new_station_tooltip')}>
                <Chip label={t('new_station_chip')} size="small" color="success" />
              </Tooltip>
            )}
          </Stack>
        ),
      },
      {
        headerName: t('table.submitted'),
        field: 'submittedAt',
        sortable: true,
        renderCell: ({ submittedAt }) => format(submittedAt, 'MMM dd, yyyy'),
      },
      {
        headerName: t('table.services'),
        field: 'services',
        sortable: false,
        renderCell: ({ services, priceModifiers }) => {
          const { total, servicesSummary, modifiers } = getPriceSummary(
            services.map((s) => s.service),
            priceModifiers,
          );

          const displayedModifiers = modifiers.filter((m) => !m.disabled);

          return (
            <Stack>
              <Typography>
                {t('total_cost', {
                  totalCost: formatPrice(Number(total)),
                })}
              </Typography>

              <Tooltip
                title={
                  <Stack direction="column">
                    {servicesSummary.map(({ id, code, afterDiscount }) => (
                      <Stack direction="row" key={id} justifyContent="space-between" width="100%">
                        <Typography variant="body2" color="white" paddingRight={2}>
                          {code}:
                        </Typography>
                        <Typography variant="body2" color="white">
                          {formatPrice(Number(afterDiscount))}
                        </Typography>
                      </Stack>
                    ))}
                    {displayedModifiers.map((m) => (
                      <Stack
                        direction="row"
                        justifyContent="space-between"
                        key={m.type}
                        width="100%"
                      >
                        <Typography variant="body2" color="white" paddingRight={2}>
                          {t(`modifier.${m.type}`, {
                            modifier: m.label,
                            absoluteModifier: m.absoluteLabel,
                          })}
                          :
                        </Typography>
                        <Typography variant="body2" color="white">
                          {formatPrice(Number(m.value))}
                        </Typography>
                      </Stack>
                    ))}
                    <Stack mt={2} direction="row" justifyContent="space-between" width="100%">
                      <Typography fontWeight="bold" variant="body2" color="white" paddingRight={2}>
                        {t('total')}
                      </Typography>
                      <Typography fontWeight="bold" variant="body2" color="white">
                        {formatPrice(Number(total))}
                      </Typography>
                    </Stack>
                  </Stack>
                }
              >
                <Stack direction="column">
                  <Typography variant="body2" color="text.secondary">
                    {t('total_services', {
                      count: services.length,
                    })}
                  </Typography>
                  {displayedModifiers.length > 0 ? (
                    <Typography fontWeight="bold">{t('additional_pricing')}</Typography>
                  ) : null}
                </Stack>
              </Tooltip>
            </Stack>
          );
        },
      },
      hasRole(ConfirmBilling)
        ? {
            type: 'actions',
            sortable: false,
            renderCell: (billingItem) => (
              <Button
                color="success"
                variant="outlined"
                startIcon={<CheckIcon />}
                onClick={() => setSelectedBillingItem(billingItem)}
              >
                {tGlobal('confirm')}
              </Button>
            ),
          }
        : undefined,
    ],
    [t, tGlobal, hasRole],
  );

  return (
    <>
      {isOffline && !items.length ? (
        <OfflinePlaceholder />
      ) : (
        <Table<BillingItem, BillingItemExtraKeys>
          columns={columns.filter(Boolean) as TableColumn<BillingItem, BillingItemExtraKeys>[]}
          rows={items}
          count={total}
          rowsPerPageOptions={[10, 50, 100]}
          initialRowsPerPage={10}
          onRowsPerPageChange={changePageSize}
          onPageChange={changePage}
          onSortChange={changeSort}
          orderBy={sorting.sortBy}
          orderDirection={sorting.direction}
          initialPage={Math.round(paging.offset / paging.limit)}
          isLoading={isLoading || isFetching}
        />
      )}

      <ConfirmCustomerFlow
        open={!!selectedBillingItem && state === 'confirmCustomer'}
        billingItem={selectedBillingItem}
        onConfirm={() => setState('confirmBilling')}
        onClose={() => setSelectedBillingItem(null)}
      />
      <ConfirmBillingItemModal
        open={!!selectedBillingItem && state === 'confirmBilling'}
        invoiceHdrId={selectedBillingItem?.job.invoiceHdrId ?? ''}
        services={selectedBillingItem?.services ?? []}
        priceModifiers={selectedBillingItem?.priceModifiers || []}
        onConfirm={async () => {
          if (!selectedBillingItem) return;

          return confirmBillingItem({ id: selectedBillingItem.id }).then(
            () => {
              notify(t('confirm_success'), {
                variant: 'secondary',
              });
            },
            () => {
              notify(t('confirm_error'), {
                variant: 'error',
              });
            },
          );
        }}
        onClose={() => setSelectedBillingItem(null)}
      />
    </>
  );
};
