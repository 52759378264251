import { JobPriceModifier as APIJobPriceModifiers } from 'api/domain/entities/JobPriceModifier';
import { add, Decimal, multiply } from 'common/types/Decimal';

import { Entity } from 'domain/types/Entity';

import { Service } from './Service';

export type JobPriceModifier = APIJobPriceModifiers;

// todo: move calculation part to backend to reuse when creating invoice
export function getPriceSummary(
  services: Pick<Service, 'id' | 'code' | 'name' | 'rate' | 'quantity' | 'discountPercentage'>[],
  priceModifiers: Entity<JobPriceModifier>[],
) {
  const servicesSummary = services.map((s) => {
    const afterQuantity = multiply(Decimal(s.rate), Decimal(s.quantity ?? 1));
    const afterDiscount = multiply(afterQuantity, Decimal(1 - (s.discountPercentage ?? 0) / 100));
    return {
      ...s,
      afterQuantity,
      afterDiscount,
    };
  });
  const subtotal = servicesSummary.reduce((total, s) => {
    return add(Decimal(total), s.afterDiscount);
  }, '0.00');
  const modifiers = priceModifiers.map((m) => {
    const label = [
      (Number(m.multiplier) < 0 ? '-' : '') +
        Math.round(Number(multiply(m.multiplier, Decimal('100.00')))) +
        '%',
      (Number(m.addition) < 0 ? '-' : '') + '$' + m.addition,
    ]
      .filter((n) => parseFloat(n.replaceAll(/[$%-]/g, '')) !== 0)
      .join(', ');

    const beforeTaxModifiers = priceModifiers
      .filter((mm) => mm.type !== 'tax')
      .reduce(
        (sum, mm) =>
          add(
            Decimal(add(multiply(Decimal(subtotal), Decimal(mm.multiplier)), mm.addition)),
            Decimal(sum),
          ),
        '0.00',
      );

    return {
      id: m.id,
      disabled: m.disabled,
      type: m.type,
      addition: m.addition,
      multiplier: m.multiplier,
      value: add(
        multiply(
          Decimal(
            m.type === 'tax' ? add(Decimal(beforeTaxModifiers), Decimal(subtotal)) : subtotal,
          ),
          Decimal(m.multiplier),
        ),
        m.addition,
      ),
      label,
      absoluteLabel: label.replaceAll('-', ''),
    };
  });
  const modifiersTotal = modifiers
    .filter((m) => !m.disabled)
    .reduce((total, m) => add(Decimal(total), m.value), '0.00');
  const total = add(Decimal(subtotal), Decimal(modifiersTotal));
  return {
    servicesSummary,
    subtotal,
    modifiers,
    modifiersTotal,
    total,
  };
}
