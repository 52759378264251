import { FC, SyntheticEvent, useMemo, useState } from 'react';
import { useFormikContext } from 'formik';
import { debounce, get } from 'lodash';

import { Entity } from 'domain/types/Entity';
import { Station } from 'domain/entities/Station';
import { useCaseSearchStation } from 'application/stations/useCases/useCaseSearchStation';
import DEFAULT_DEBOUNCE_TIME from 'infrastructure/utils/defaultDebounceTime';
import { useTranslationPrefix } from 'infrastructure/translations/i18n';
import { Autocomplete } from 'targets/web/components';
import { useNetworkStatus } from 'targets/web/modules/dashboard/hooks/useNetworkStatus';
import { FormikTextField } from 'targets/web/modules/dashboard/components';

import { PrimaryJobDetailsData } from './PrimaryJobDetails';

const isValueFreeSolo = (value: PrimaryJobDetailsData['facility']): boolean =>
  !!value.id && !!value.name && value.id === value.name;

export const FacilityAutocomplete: FC<{
  disabled?: boolean;
  stationEditable?: boolean;
  optional?: boolean;
}> = ({ disabled, stationEditable, optional }) => {
  const { isOffline } = useNetworkStatus();

  const { values, errors, touched, initialValues, setFieldValue } =
    useFormikContext<PrimaryJobDetailsData>();
  const t = useTranslationPrefix('jobs.details_form.primary_details');

  const [stationPhrase, setStationPhrase] = useState('');
  const { items = [], isLoading } = useCaseSearchStation({ phrase: stationPhrase });

  const stations = useMemo(() => {
    if (!initialValues.facility?.id) {
      return items;
    }

    if (!items.find(({ id }) => id === initialValues.facility?.id)) {
      return [initialValues.facility].concat(items);
    }

    return items;
  }, [initialValues.facility, items]);

  const handleInputChange = useMemo(
    () =>
      debounce((event: SyntheticEvent, newInputValue: string) => {
        if (event?.type !== 'change' && newInputValue) {
          return;
        }

        setStationPhrase(newInputValue);
      }, DEFAULT_DEBOUNCE_TIME),
    [],
  );

  return (
    <Autocomplete<Entity<Station>, false, false, boolean>
      label={t('facility_label')}
      loading={isLoading}
      disabled={disabled || !stationEditable}
      freeSolo={isOffline}
      autoSelect={isOffline}
      options={stations}
      value={values.facility?.id ? values.facility : null}
      getOptionLabel={(option) => (typeof option === 'string' ? option : option.name)}
      isOptionEqualToValue={(option, value) => option.id === value.id}
      filterOptions={(x) => x}
      onInputChange={handleInputChange}
      data-testname="facility"
      onChange={(_, value) =>
        setFieldValue('facility', typeof value === 'string' ? { name: value, id: value } : value)
      }
      renderInput={(params) => {
        const isFreeSolo = values.facility && isValueFreeSolo(values.facility);
        const hasError = !!get(errors, 'facility') && (!!get(touched, 'facility') || isFreeSolo);

        return (
          <FormikTextField
            {...params}
            name="facility"
            inputProps={{
              ...params.inputProps,
              required: !optional,
            }}
            error={hasError}
            helperText={
              hasError ? (isFreeSolo && isOffline ? t('error_free_solo') : t('error')) : undefined
            }
            label={t('facility_label')}
            required={!optional}
          />
        );
      }}
    />
  );
};
