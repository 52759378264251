import { ZodType, z } from 'zod';
import {Decimal as DecimalJS} from 'decimal.js';

export type Decimal = string & { readonly '': '' };

export const decimal = () => z.string() as unknown as ZodType<Decimal>;

export const Decimal = (value: string | number): Decimal => decimal().parse(value.toString());

export const add = (a: Decimal, b: Decimal): Decimal => decimal().parse(new DecimalJS(a).add(b).toString());

export const subtract = (a: Decimal, b: Decimal): Decimal => decimal().parse(new DecimalJS(a).minus(b).toString());

export const divide = (a: Decimal, b: Decimal): Decimal => decimal().parse(new DecimalJS(a).div(b).toString());

export const multiply = (a: Decimal, b: Decimal): Decimal => decimal().parse(new DecimalJS(a).times(b).toString());

export const toFixed = (a: Decimal, precision: number): Decimal => decimal().parse(new DecimalJS(a).toFixed(precision).toString());
