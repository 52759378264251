import { useCallback, useState } from 'react';

import { BillingStatus } from 'domain/entities/BillingStatus';
import { JobStatus } from 'domain/entities/JobStatus';
import { JobSummary } from 'domain/entities/JobSummary';
import { ID } from 'domain/types/ID';
import { useCaseUpdateJob } from 'application/jobs/useCases/useCaseUpdateJob';
import useSnackbar from 'targets/web/modules/dashboard/hooks/useSnackbar';
import { JobCostSummaryModal } from 'targets/web/modules/jobs/components/JobCostSummaryModal/JobCostSummaryModal';

import { BillingStatusChip } from './BillingStatusChip';

type Props = Pick<JobSummary, 'id' | 'billingStatus' | 'services' | 'priceModifiers' | 'status'> & {
  optimisticPermissions?: boolean;
};

export const JobBillingStatusChip = ({
  id,
  billingStatus,
  status,
  optimisticPermissions,
  services,
  priceModifiers,
}: Props) => {
  const notify = useSnackbar();
  const [costSummaryOpen, setCostSummaryOpen] = useState(false);

  const { updateJobBillingStatus } = useCaseUpdateJob();

  const handleStatusChipChange = useCallback(
    async (status: BillingStatus, enabledPriceModifierIds?: ID[]) => {
      await updateJobBillingStatus(
        {
          jobId: id,
          billingStatus: status,
          enabledPriceModifierIds,
        },
        {
          onError: () => {
            notify("Error occurred while updating the Job's billing status", {
              variant: 'error',
            });
          },
        },
      );
    },
    [id, notify, updateJobBillingStatus],
  );

  return (
    <>
      <BillingStatusChip
        jobId={id}
        billingStatus={billingStatus}
        onSelect={(status) => {
          if (status === BillingStatus.Values.billable) {
            setCostSummaryOpen(true);
            return;
          }
          handleStatusChipChange(status);
        }}
        optimisticPermissions={optimisticPermissions}
        editable={
          status !== JobStatus.Values.scheduled &&
          status !== JobStatus.Values.in_progress &&
          status !== JobStatus.Values.canceled
        }
      />
      <JobCostSummaryModal
        open={costSummaryOpen}
        onClose={() => setCostSummaryOpen(false)}
        onAccept={(enabledPriceModiferIds) => {
          handleStatusChipChange(BillingStatus.Values.billable, enabledPriceModiferIds);
          setCostSummaryOpen(false);
        }}
        priceModifiers={priceModifiers}
        services={services}
      />
    </>
  );
};
